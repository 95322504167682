.drawer-container {
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 0;
    // @include respond-to('small') {
    //     margin: 0 0 0 25%;
    // }
}

.drawer-list-item {
    border-bottom: solid 1px $mid-grey;
    min-height: 60px;
    min-width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-weight: 300;
    font-size: 1.1rem;
}

.drawer-list-item:hover {
    cursor: pointer;
    background-color: $light_blue;
    text-decoration: none;
    color: $white;
    font-weight: 500;
}

.drawer-button-icon {
    margin: 0 10px;
    font-size: 1.5rem;
}