// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.flow-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    color: $white;
    border-radius: 75px;
    font-size: 24px;
    min-width: 350px;
    min-height: 85px;
    padding-left: 30px;
}

.flow-button-icon {
    font-size: 40px;
    margin-right: 20px;
}

.icon__left {
    flex-flow: row nowrap;
}

.icon__right {
    flex-flow: row-reverse nowrap;
}

.is_start {
    justify-content: space-evenly;
    text-decoration: none;
    font-size: 1.6rem;
    // overflow: hidden;
    text-align: center;

    // @include respond-to('small') {
    //     max-width: 50%;
    // }
}

.button-link:hover,
.button-link:active,
.button-link:link,
.button-link:visited,
.is_start:hover,
.is_start:active,
.is_start:link,
.is_start:visited {
    text-decoration: none;
}

.button-link .is_start {
    margin-top: 20px
}

.optin__button {
    min-width: 350px;
    min-height: 90px;
    border-radius: 75px;
    font-size: 24px;
    margin: 5px;
}

.email-optin-buttons {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
}

.optin__button_email {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 1.1rem;
}

.offer__button_single {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 90px;
    background-color: $medium-blue;
    font-size: 24px;
    border-radius: 90px;
    color: $white;
    margin-top: 25px;
}

.offer__button_single-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 50px 20px 0;
}

// .offer__button_single-title {}

.offer__button_single-subtitle {
    font-size: 0.8rem;
    font-weight: 300;
}

.offer-button-icon {
    font-size: 2.2rem;
    margin: 10px 20px 10px 50px;
}

.offer__button_multi {
    margin: 10px;
    min-width: calc(50% - 40px);
    min-height: 65px;
    border-radius: 8px;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include respond-to('small') {
        width: calc(100vw - 50px);
    }
}

.four-button_group {
    margin: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
}

.button_multi-left {
    height: 65px;
    width:50px;
    background-color: $button-shade;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.button_multi-right {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: 400;
    padding: 0 10px;
}

.offer-multi_button-icon {
    font-size: 2.2rem;
    padding: 5px;
}

// .is_start .sticky {
//     position: fixed;
//     top: 5px;
//     height: 40px;
//     width: 190px;
// }

