.card-deck {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include respond-to('small') {
        padding: 0 10px;
    }
}

.card-text {
    justify-content: flex-start;
    padding-top: 10px;
    @include respond-to('small') {
        font-size: 1rem;
    }
}

.card-body {
    padding: 1.6rem;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}

.card-body:hover {
    opacity: 0.4;
    cursor: pointer;
}