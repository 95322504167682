.sponsored-text {
    font-size: 0.8rem; 
    font-weight: 300;
}

.offer-page__main {
    width: max-content;
    min-height: 350px;

    @include respond-to('small') {
        width: auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        padding-top: 50px;
    }
}

.offer-page__main-1 {
    width: max-content;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include respond-to('small') {
        width: auto;
        min-height: 100vh;
        padding-top: 50px;
    }
}

.offer-page__main-multi {
    width: fit-content;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include respond-to('small') {
        width: auto;
        min-height: 100vh;
        padding-top: 50px;
    }
}

.offer-header-text {
    font-weight: 400;
    font-size: 1.5rem;
}

.offer-container {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}