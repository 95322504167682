// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header-logo-group {
    max-width: 200px;
    display: inline-flex;
    align-items: center;
}

.header-logo-icon {
    width: 60px;
    margin-right: 10px;
}

.header-text-logo {
    @include respond-to('small') {
        display: none;
    }
}

.header-container {
    height: 60px;
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 15vw;
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    @include respond-to('small') {
        padding: 0 20px;
    }
}

.header-menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 1.8rem;
}

.header-menu-icon:hover {
    cursor: pointer;
}