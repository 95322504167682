
.app-bg_container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100vw;
	// background-image: url('../../assets/images/flag_left.png'), url('../../assets/images/flag_right.png');
  	// background-repeat: no-repeat;
	background-color: #ffffff;
	// height: 100%;
	min-height: 400px;
	background-position: left, right;
	background-size: contain;
	position: absolute;
	top: 60px;
	@include respond-to('small') {
        height: 60px;
    }
}
