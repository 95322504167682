.featured-article-container {
    width: 70%;
    @include respond-to('small') {
        width: 100%;
    }
}

.featured-article-list {
    width: 30%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    @include respond-to('small') {
        width: 100%;
        margin-top: 25px;
        min-height: unset;
    }
}

.featured-article-title {
    color: $dark-blue;
    font-size: 2.5rem;
    letter-spacing: -2px;
    line-height: 1.1em;
    @include respond-to('small') {
        font-size: 28px;
        padding: 20px;
    }
}

.featured-article-list-item {
    min-height: max-content;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    @include respond-to('small') {
        min-height: fit-content;
        padding: 0 20px;
    }
}

.article-title {
    color: $dark-blue;
    font-size: 1.8rem;
    letter-spacing: -2px;
    line-height: 1.1em;
    @include respond-to('small') {
        font-size: 18px;
        letter-spacing: -1px;
    }
}

.keyword-link-container {
    display: inline-flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
}

.category-item {
    font-size: 0.5rem;
    text-overflow: initial;
    font-weight: 300;
    color: $mid-grey;
    text-decoration: underline $light-grey;
}

.category-item:hover {
    color: $light-grey;
    text-decoration: none;
}

.title-hover:hover {
    cursor: pointer;
    opacity: 0.3;
    text-decoration: underline
}
