.feed-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 10%;
    @include respond-to('small') {
        padding: 0;
    }
}

.feed-row {
    min-width: 100%;
    min-height: 550px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    @include respond-to('small') {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    text-align: left;
}

.row-content {
    display: inline-flex;
    @include respond-to('small') {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}

.row-content-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include respond-to('small') {
        padding: 0 20px;
    }
}

.row-title {
    color: $red;
    font-size: 1.2rem;
    min-width: 100%;
    margin-bottom: 25px;
    padding-left: 15px;
}

.bottom-border {
    // border-bottom: 1px solid rgba(126, 124, 124, 0.25);
    // border-bottom: 2px solid #f2f2f2;
    line-height: 1.4em;
}

.right-border {
    border-right: 1px solid rgba(126, 124, 124, 0.25);
    line-height: 1.4em;
}

.keyword-link {
    line-height: 1;
    padding: 0 10px 5px 0;
    // border-right: 1px solid rgba(126, 124, 124, 0.25);
    // text-align: center;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
}