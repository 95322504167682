@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendors/normalize',
  'vendors/bootstrap';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/grid',
  'layout/rss_feed',
  'layout/header',
  'layout/drawer',
  'layout/flows',
  'layout/footer';

// 5. Components
@import
  'components/loaders',
  'components/footer',
  'components/featured_row',
  'components/info_row',
  'components/latest_row',
  'components/popular_row',
  'components/editorial_row',
  'components/breadcrumbs',
  'components/navbar',
  'components/side_column',
  'components/button';

// 6. Page-specific styles
@import
  'pages/offers_page',
  'pages/optins',
  'pages/content_feed',
  'pages/get_started',
  'pages/home';

// 7. Themes
@import
  'themes/default';