.latest-row-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 100px;
    @include respond-to('small') {
        padding: 0; 
        margin-top: 25px;
    }
    min-height: max-content;
}

.latest-article-row {
    display: inline-flex;
    padding: 20px 0;
    @include respond-to('small') {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
}

.latest-item-column {
    width: 33%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @include respond-to('small') {
        width: 100%;
        padding: 10px;
    }
}

.tags-left {
    justify-content: flex-start;
}

.card-text,
.text-column {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    color: $mid-grey;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.25em;
    @include respond-to('small') {
        display: none;
    }
}

.card-text {
    @include respond-to('small') {
        display: flex;
    }
}

.image-column {
    max-height: fit-content;
}