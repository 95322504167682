.start-title-text {
    color: $text-primary;
    font-size: 46px;
    font-weight: 800;
    padding-top: 2vh;

    @include respond-to('small') {
        font-size: 1.8rem;
        padding: 20px 50px 0 50px;
        line-height: 1.1;
    }
}

.get-started__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10vh 0;
}