.optin-container {
    min-width: 100%;
    padding: 30px 10px 0 10px;

    @include respond-to('small') {
        padding: 0 30px;
    }
}

.email-optin-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.optin-header-text {
    font-size: 26px;
    font-weight: 400;
    line-height: 1.1;
}

.optin-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    margin: 2vh 0;
}

.row2 {
    @include respond-to('large') {
        justify-content: center;
    }
}

.row3 {
    text-align: left;
    line-height: 1.2;
}

.article-heading {
    min-width: 100%;
    font-size: 1.7rem;
    padding-bottom: 20px;
}

.debt-article-heading {
    min-width: 100%;
    font-size: 1.4rem;
    padding-bottom: 20px;
}

.article-sub-heading {
    margin: 1em 0;
    font-size: 1.15rem;
    font-weight: 500;
}

.article-content {
    font-weight: 400;
    font-size: 0.9rem;
}

.sub {
    font-weight: 400;
    font-size: 1rem;
}

.article-content-item {
    margin-bottom: 20px;
}

.email-optin-card {
    margin: 50px;
    max-width: 550px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 25px 0 #888;

    @include respond-to('small') {
        margin: 25px 10px 50px 10px;
    };
}

.email-optin-text {
    text-align: left;
    font-weight: 300;
    font-size: 1.3rem;
    margin-bottom: 20px;
}

.email-form-container {
    min-width: 280px;
}

.optin-input-field {
    width: 80%;
    line-height: 30px;
    border: 1px solid #2c9dc5;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.3rem;
    margin: 0 10% 2% 10%;
    @include respond-to('small') {
        width: 100%;
        margin: 0;
    };
}

.email-terms-container {
    width: 75%;
    font-size: 12px;
    margin: 15px auto;
    text-align: justify;
    @include respond-to('small') {
        width: 100%;
        margin: 15px 0;
    };
}

.email_terms_box {
    display: inline-block;
    margin: 0 5px 0 0;
}

.email_terms_text {
    display: inline;
    font-weight: 400;
}

.email_terms_links {
    text-decoration: underline;
}

.email-button-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0 30%;

    @include respond-to('small') {
        padding: 0;
    };
}

.email_submit-button {
    margin: 3%;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.next-button-icon {
    font-size: 1.6rem;
    margin-left: 10%;
}

.button-text {
    font-size: 1.2rem;
}

.input-error {
    color: red;
}

.no-thanks {
    background-color: transparent;
    color: $light-grey;
    text-decoration: underline;
    font-weight: 400;
    margin-bottom: 3%;
}