.breadcrumb-container {
    min-height: 100px;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 1.1rem;
    background-image: rgba(182, 211, 223, 0.4); /* fallback for old browsers */
    background-image: -webkit-radial-gradient(farthest-side at 50% 30%,rgba(182, 211, 223, 0.4) 70%, rgba(255,255,255,0.01));
    background-image: radial-gradient(farthest-side at 50% 30%,rgba(182, 211, 223, 0.4) 70%, rgba(255,255,255,0.01));
    color: $text-breadcrumbs;
    padding: 0 0 10px 0;
    @include respond-to('small') {
        padding: 0;
        min-height: 75px;
    }
}
.breadcrumb-row {
    height: 30px;
    min-width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 1.1rem;
    color: $text-breadcrumbs;
    @include respond-to('small') {
        display: flex;
        flex-flow: row wrap;
        height: unset;
    }
}

.crumb-container {
    height: 25px;
    border: 1px solid $text-breadcrumbs;
    color: $text-breadcrumbs;
    border-radius: 25px;
    padding: 0 7px;
    font-weight: 400;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.crumb-container:hover {
    color: $white;
    background-color: $button-blue;
}

.crumb-box {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.breadcrumb-key {
    font-weight: 600;
    text-decoration: dashed;
}