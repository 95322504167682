.centered-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flow-content__container {
    width: 100%;
    display: inline-flex;
    flex-flow: row stretch;
    justify-content: space-between;
    align-items: flex-start;
}

.flow-content__flag {
    width: 20%;
}

.flow-content__choose-container {
    padding: 5vh 0;
    width: 60%;
    margin: 0;
    min-height: 400px;
    @include respond-to('small') {
        margin: 10px 25px 25px 25px;
    }
}

.with_crumbs {
    padding: 0 0 5vh 0;
}

.flow-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 400px;
}

.flow-button-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 0;
    @include respond-to('small') {
        min-width: 350px;
        padding: 10px;
        margin-top: 0;
    }
}

.flow-title-text {
    font-size: 26px;
    font-weight: 400;
}

/* Style the accordion section */
// .accordion__section {
//     display: flex;
//     flex-direction: column;
//     min-width: max-content;
//     display: flex;
//     align-items: center;
//     border: none;
//     outline: none;
//     transition: background-color 0.6s ease;
// }

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
    overflow: hidden;
    min-width: 100%;
    transition: min-height 0.8s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.flow-container {
    padding: 2vh 0 5vh 0;
    max-width: 800px;
}